import { Card as BCard } from "react-bootstrap";

export interface ICardProps {
  title: string;
  loading: boolean;
  children: any;
}

export const Card = (props: ICardProps) => {
  
  return (
    <BCard>
      <BCard.Header>{props.title}</BCard.Header>
      <BCard.Body>
        {props.loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-grow text-danger" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          ) : props.children}
      </BCard.Body>
    </BCard>
  );
}
