import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../state";

export interface IScreen {
  children: any;
  ignoreAuth?: boolean;
}

export const Screen = (props: IScreen) => {
  let navigate = useNavigate();
  let [isLoggedIn] = useGlobalState("isLoggedIn");

  useEffect(() => {
    if(!props.ignoreAuth && !isLoggedIn){
      navigate("/login");
    }
  }, [props.ignoreAuth, isLoggedIn, navigate])


  return (
    <div className="screen">
      {props.children}
    </div>
  )
}