import { makeCall } from "./client";

export class UserAPI {
  /**
   * Login
   * @param username 
   * @param password 
   * @returns 
   */
  public login(username: string, password: string): Promise<any> {
    return makeCall("post", `users/login`, {username, password });
  }

  /**
   * Logout
   * @returns 
   */
  public logout(): Promise<any> {
    return makeCall("post", `users/logout`);
  }

  /**
   * Get the user's family
   * @param userId 
   * @returns 
   */
  public getFamily(userId: number): Promise<any> {
    return makeCall("get", `users/${userId}/family`);
  }

  /**
   * Get the profile
   * @param schoolId 
   * @param userId 
   * @returns 
   */
  public getProfile(schoolId: number, userId: number): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}`);
  }

  /**
   * Update the profile
   * @param schoolId 
   * @param userId 
   * @param profileData 
   * @returns 
   */
  public updateProfile(schoolId: number, userId: number, profileData: any): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/users/${userId}`, profileData);
  }

  /**
   * Begin the password reset
   * @param username 
   * @param emailAddress 
   * @param params 
   * @returns 
   */
  public resetPassword(username: string, emailAddress: string = "", params: any = {}): Promise<any> {
    const data = {
      username,
      emailAddress,
      sourceURL: "https://student.kdojang.com",
    };
    return makeCall("post", `users/reset`, data);
  }

  /**
   * Verify the reset
   * @param username 
   * @param token 
   * @param password 
   * @param params 
   * @returns 
   */
  public verifyPasswordToken(username: string, token: string, password: string, params: any = {}): Promise<any> {
    const data = {
      username,
      token,
      password
    };
    return makeCall("post", `users/reset/verify`, data);
  }

  /**
   * Get the user message preferences
   * @param schoolId 
   * @param userId 
   * @param token 
   * @returns 
   */
  public getUserPreferences(schoolId: number, userId: number, token: string){
    let data: any = {}
    if(token !== ""){
      data.t = token;
    }
    return makeCall("get", `schools/${schoolId}/users/${userId}/messages/preferences`, data);
  }

  /**
   * Save the user message preferences
   * @param schoolId 
   * @param userId 
   * @param token 
   * @param preferences 
   * @returns 
   */
  public saveUserPreferences(schoolId: number, userId: number, token: string, preferences: any){
    let data: any = {...preferences, token}

    return makeCall("patch", `schools/${schoolId}/users/${userId}/messages/preferences`, data);
  }
}