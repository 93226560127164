import { useState } from "react";
import {  Navbar, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { useGlobalState, clear } from "../../state";

function TopNav(props: any) {
  const navigate = useNavigate();
  // this is dumb...
  const [expanded, setExpanded] = useState(false);
  const [isLoggedIn] = useGlobalState("isLoggedIn");
  const [schoolName] = useGlobalState("schoolName");
  const [userDisplay] = useGlobalState("userDisplay");

  const logout = () => {
    clear();
    setExpanded(false);
    navigate("/login");
  }

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" onSelect={toggleExpanded} expanded={expanded}>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleExpanded} />
        <Navbar.Brand>
          {schoolName !== "" ? schoolName : "KDojang"} {userDisplay !== "" ? " - " + userDisplay : ""}
        </Navbar.Brand>
        {isLoggedIn ? (
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <div className="nav-link" onClick={toggleExpanded}><Link to="/dashboard" className="nav-link"><i className="bi bi-house-fill navbar-dark-icon" /> Home</Link></div>
            <div className="nav-link" onClick={toggleExpanded}><Link to="/profile" className="nav-link"><i className="bi bi-person-fill navbar-dark-icon" /> Account</Link></div>
            <div className="nav-link" onClick={toggleExpanded}><Link to="/schedule" className="nav-link"><i className="bi bi-calendar-week-fill navbar-dark-icon" /> Schedule</Link></div>
            <div className="nav-link" onClick={toggleExpanded}><Link to="/learning" className="nav-link"><i className="bi bi-book-fill navbar-dark-icon" /> Learning</Link></div>
          </Nav>
          <Nav>
            <div className="nav-link" onClick={logout}><i className="bi bi-box-arrow-right navbar-dark-icon" /> Logout</div>
          </Nav>
        </Navbar.Collapse>
        ): (
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
            <div className="nav-link"><Link to="/login" className="nav-link"><i className="bi bi-person-fill navbar-dark-icon" /> Log In</Link></div>
            </Nav>
          </Navbar.Collapse>
        )}

    </Navbar>
  )
}

export default TopNav;