import { createGlobalState } from 'react-hooks-global-state';

// I hate this. Is there a better way?

export interface IGlobalState {
  isLoggedIn: boolean;
  userId: number;
  userDisplay: string;
  schoolId: number;
  schoolName: string;
  familyId: number;
  family: any[];
}

// initial state
const globalPersistenceKey = "kdojang-student";

const initialState: IGlobalState = {
  isLoggedIn: false,
  userId: 0,
  userDisplay: "",
  familyId: 0,
  schoolId: 0,
  schoolName: "",
  family: [],
};

export const { useGlobalState, setGlobalState, getGlobalState } = createGlobalState(initialState);

export const saveGlobalStateToLocalStorage = () => {
  const st: any = {
    isLoggedIn: getGlobalState("isLoggedIn"),
    userId: getGlobalState("userId"),
    userDisplay: getGlobalState("userDisplay"),
    familyId: getGlobalState("familyId"),
    schoolId: getGlobalState("schoolId"),
    schoolName: getGlobalState("schoolName"),
  };
  saveEntireStateToLocalStorage(st);
}

export const saveEntireStateToLocalStorage = (state: IGlobalState) => {
  localStorage.setItem(globalPersistenceKey, JSON.stringify(state));
};

export const loadStateFromLocalStorage = () => {
  const stateString = localStorage.getItem(globalPersistenceKey);
  if(stateString){
    const state: IGlobalState = JSON.parse(stateString);
    setGlobalState("isLoggedIn", state.isLoggedIn);
    setGlobalState("userId", state.userId ? state.userId : 0);
    setGlobalState("familyId", state.familyId ? state.familyId : 0);
    setGlobalState("schoolId", state.schoolId ? state.schoolId : 0);
    setGlobalState("schoolName", state.schoolName ? state.schoolName : "");
    setGlobalState("userDisplay", state.userDisplay ? state.userDisplay : "");
  }
};

export const clear = () => {
  localStorage.clear();
  setGlobalState("isLoggedIn", initialState.isLoggedIn);
  setGlobalState("userId", initialState.userId);
  setGlobalState("familyId", initialState.familyId);
  setGlobalState("schoolId", initialState.schoolId);
  setGlobalState("schoolName", initialState.schoolName);
  setGlobalState("userDisplay", initialState.userDisplay );
  
}