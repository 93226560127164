import { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserAPI } from "../../../api";
import { useGlobalState } from "../../../state";

import { Card } from "../../structure";

export const ProfileCard = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    emailAddress: "",
    dob: "",
    pronouns: "",
  });
  const [userId] = useGlobalState("userId");
  const [schoolId] = useGlobalState("schoolId");

  useEffect(() => {
    const fetch = async () => {
      const profile = await UserAPI.getProfile(schoolId, userId);
      setProfile(profile.body.data);
    }
    fetch();
  }, [schoolId, userId])

  const updateProfileField = (e: any) => {
    const {id, value } = e.target;
    setProfile(prevState => ({
      ...prevState,
      [id]: value,
    }))
  }

  const updateProfileOnServer = async () => {
    setLoading(true);
    try{
      await UserAPI.updateProfile(schoolId, userId, profile);
      setLoading(false);
    }catch(err){
      setLoading(false);
    }
  }

  return (
    <Row>
      <Col>
        <Card title="Profile" loading={loading}>
          <Form.Group className="form-group">
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" value={profile.firstName} id="firstName" onChange={updateProfileField} />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" value={profile.lastName} id="lastName" onChange={updateProfileField} />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" value={profile.emailAddress} id="emailAddress" onChange={updateProfileField} />
          </Form.Group>
          <Form.Group className="form-group">
            <div className="d-grid gap-2">
              <button className="btn btn-block btn-primary" onClick={updateProfileOnServer}>Update</button>
            </div>
          </Form.Group>
          <Form.Group className="form-group">
            <div className="d-grid gap-2">
              <Link to="/messages/preferences" className="btn-link">Manage Message Preferences</Link>
            </div>
          </Form.Group>
        </Card>
      </Col>
    </Row>
  )
}