import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ScheduleAPI } from "src/api";
import { Card } from "src/components/structure"
import { useGlobalState } from "src/state";

export const DashboardUpcomingEvents = (props: any) => {
  const [ loading, setLoading ] = useState(true);
  const [ events, setEvents ] = useState([]);

  const [ schoolId ] = useGlobalState("schoolId");

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const start = DateTime.now().toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
      const end = DateTime.now().plus({ day: 1}).endOf("day").toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
      try{
        const res = await ScheduleAPI.getEventsBetweenDays(schoolId, start, end, { count: 5 });
        const eventsParsed: any = [];
        for(const e of res.body.data){
          const startDisplay = DateTime.fromISO(e.startTime).toLocal().toFormat("MMM dd 'at' h:mm a");
          const endDisplay = DateTime.fromISO(e.endTime).toLocal().toFormat("h:mm a");
          e.startDisplay = startDisplay;
          e.endDisplay = endDisplay;
          eventsParsed.push(e);
        }
        setEvents(eventsParsed);
      }catch(err){ console.log(err); };
      setLoading(false);
    };
    fetch();
  }, [schoolId]);

  return (
    <Card title="Upcoming Events" loading={loading}>
      <Row className="row-spacing row-list row-list-header">
        <Col xs={6} sm={6} lg={4}>
          From
        </Col>
        <Col xs={6} sm={6} lg={4}>
          To
        </Col>
        <Col xs={12} sm={12} lg={4}>
          Event
        </Col>
      </Row>
      {events.length === 0 && (
        <Row>
          <Col>
            No events scheduled in next 24 hours
          </Col>
        </Row>
      )}
      {events.map((event: any) => {
        return (
          <Row key={event.id} className="row-spacing row-list">
            <Col xs={6} sm={6} lg={4}>
              {event.startDisplay}
            </Col>
            <Col xs={6} sm={6} lg={4}>
              {event.endDisplay}
            </Col>
            <Col xs={12} sm={12} lg={4}>
              {event.name}
            </Col>
          </Row>
        )
      })}

      <Row style={{ marginTop: 10 }}>
        <Col>
          <div className="d-grid gap-2">
            <Link to="/schedule" className="btn btn-block btn-primary">See Schedule</Link>
          </div>
        </Col>
      </Row>
    </Card>
  )
}