import { Col, Row } from "react-bootstrap"
import { Screen } from "src/components/structure"
import { DashboardLastAttendance } from "./DashboardLastAttendance"
import { DashboardNews } from "./DashboardNews"
import { DashboardUpcomingEvents } from "./DashboardUpcomingEvents"

export const Dashboard = (props: any) => {

  return (
    <Screen>
      <Row>
        <Col sm={12} lg={4}>
          <DashboardNews />
        </Col>
        <Col sm={12} lg={4}>
          <DashboardLastAttendance />
        </Col>
        <Col sm={12} lg={4}>
          <DashboardUpcomingEvents />
        </Col>
      </Row>
    </Screen>
  )
}