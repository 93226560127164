import { makeCall } from "./client";

export class NewsAPI {
  public getNews(queryData: Object = {}): Promise<any> {
    return makeCall("get", `news`, queryData);
  }

  public getSchoolNews(schoolId: number, queryData: Object = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/news`, queryData);
  }
}