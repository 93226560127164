import { useState, useEffect } from "react";
import { Form, Row, Col, Modal } from "react-bootstrap";
import { DateTime } from "luxon";
import { useAlert } from "@blaumaus/react-alert";
import { ScheduleAPI, UserAPI } from "../../../api";
import { useGlobalState } from "../../../state";

import { Card, Screen } from "../../structure";

export const Schedule = (props: any) => {
  const [ loading ] = useState(false);
  const [ events, setEvents ] = useState([]);
  const [ day, setDay ] = useState(DateTime.local().toFormat("yyyy-MM-dd"));
  const [ showAttendanceModal, setShowAttendanceModal ] = useState(false);
  const [ family, setFamily ] = useState<any[]>([]);
  const [ eventId, setEventId ] = useState(0);

  const [ userId ] = useGlobalState("userId");
  const [ schoolId ] = useGlobalState("schoolId");

  const alert = useAlert();

  useEffect(() => {
    const fetch = async () => {
      const parts = day.split("-");
      if(parts.length !== 3){
        return;
      }
      const y = parseInt(parts[0]);
      const m = parseInt(parts[1]);
      const d = parseInt(parts[2]);
      const ls = DateTime.local(y, m, d, 0, 0, 0).toUTC();
      const start = ls.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
      const le = DateTime.local(y, m, d, 23, 59, 59).toUTC();
      const end = le.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");

      const result = await ScheduleAPI.getEventsBetweenDays(schoolId, start, end)
      setEvents(result.body.data);

      const familyResult = await UserAPI.getFamily(userId);
      setFamily(familyResult.body.data);
      
    }
    fetch();
  }, [day, schoolId, userId]);

  const setDate = (e: any) => {
    setDay(e.target.value);
  }

  const toggleEventModal = async (eventId: number) => {
    // get the family and the attendance....
    const attResult = await ScheduleAPI.getEventAttendance(schoolId, eventId);
    const att = attResult.body.data;
    const newFamily: any[] = [];
    for(const f of family){
      f.didAttend = false;
      for(const a of att){
        if(a.id === f.id){
          f.didAttend = true;
          break;
        }
      }
      newFamily.push(f);
    }
    setEventId(eventId);
    setFamily(newFamily);
    setShowAttendanceModal(true);
  }

  const markAttendance = async (eventId: number, memberId: number) => {
    try{
      await ScheduleAPI.saveUserAttendance(schoolId, eventId, memberId)
      setShowAttendanceModal(false);
      alert.success("Attendance marked!")
    }catch(err){}
    
  }

  const removeAttendance = async (eventId: number, memberId: number) => {
    try{
      await ScheduleAPI.removeUserAttendance(schoolId, eventId, memberId)
      setShowAttendanceModal(false);
      alert.success("Attendance marked!")
    }catch(err){}
    
    
  }

  return (
    <Screen>
      <Row>
        <Col sm={12} lg={6} className="offset-lg-3">
          <Card title="Schedule" loading={loading}>
            <Form.Group style={{marginBottom: 20}}>
              <Form.Label>For Day:</Form.Label>
              <Form.Control type="date" onChange={setDate} value={day} />
            </Form.Group>

            <Row className="row-spacing row-list row-list-header">
              <Col lg={5} sm={5} xs={5}>
                Event
              </Col>
              <Col lg={6} sm={6} xs={6}>
                When
              </Col>
              <Col lg={1} sm={1} xs={1}>
              </Col>
            </Row>
            {events.length === 0 && (
              <Row>
                <Col>
                  <p>No events are available on that day</p>
                </Col>
              </Row>
            )}
            {events.map((event: any) => {
              const from = DateTime.fromISO(event.startTime).toLocal().toFormat("h:mm");
              const to = DateTime.fromISO(event.endTime).toLocal().toFormat("h:mm a");
              return (
                <Row key={event.id} className="row-spacing row-list">
                  <Col lg={5} sm={5} xs={5}>
                    {event.name}
                  </Col>
                  <Col lg={6} sm={6} xs={6}>
                    {from} to {to}
                  </Col>
                  <Col lg={1} sm={1} xs={1} onClick={() => toggleEventModal(event.id)}>
                    <i className="bi bi-calendar-check-fill navbar-dark-icon" />
                  </Col>
                </Row>
              )
            })}
          </Card>
        </Col>
      </Row>
      <Modal show={showAttendanceModal} onHide={() => setShowAttendanceModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Mark Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {family.map((member: any) => {
            return (
              <Row key={member.id} className="row-spacing row-list">
                <Col>{member.firstName}</Col>
                <Col>
                  {member.didAttend ? (
                    <i className="bi bi-patch-check-fill navbar-dark-icon" onClick={() => {removeAttendance(eventId, member.id)}} />
                  ) : (
                    <i className="bi bi-patch-check navbar-dark-icon" onClick={() => {markAttendance(eventId, member.id)}} />
                  )}
                </Col>
              </Row>
            )
          })}
        </Modal.Body>
      </Modal>
    </Screen>
  )
}