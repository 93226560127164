import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import { UserAPI } from "../../../api";

import { Card, Screen } from "../../structure";
import { useGlobalState } from "../../../state";

// keep in mind that this may be unauthenticated

export const ManageMessagePreferences = (props: any) => {
  const [ loading, setLoading ] = useState(false);
  const [ email, setEmail ] = useState("");
  const [ receiveAttendance, setReceiveAttendance ] = useState("yes");
  const [ receiveBilling, setreceiveBilling ] = useState("yes");
  const [ receiveContract, setReceiveContract ] = useState("yes");
  const [ receiveDirect, setReceiveDirect ] = useState("yes");
  const [ receiveMarketing, setReceiveMarketing ] = useState("yes");
  const [ receiveSummaries, setReceiveSummaries ] = useState("yes");
  const { schoolId, userId } = useParams();
  const navigate = useNavigate();  
  const [ userIdGlobal ] = useGlobalState("userId");
  const [ schoolIdGlobal ] = useGlobalState("schoolId");

  // the path may not include these, so we need to check
  let schoolIdInt = 0;
  let userIdInt = 0;
  if(!schoolId || schoolId === "0"){
    // set the school id from the global
    schoolIdInt = schoolIdGlobal
  } else {
    // parse from path
    schoolIdInt = parseInt(schoolId ? schoolId : "0");
  }

  if(!userId || userId === "0"){
    // set the user id from the global
    userIdInt = userIdGlobal;
  } else {
    // parse from path
    userIdInt = parseInt(userId ? userId : "0");
  }

  const params = new URLSearchParams(window.location.search)
  const t = params.get('t');
  let token = "";
  if(t){
    token = t;
  }


  useEffect(() => {
    const fetch = async () => {
      try{
        const result = await UserAPI.getUserPreferences(schoolIdInt, userIdInt, token);
        const d = result.body.data;
        setReceiveAttendance(d.receiveAttendance);
        setreceiveBilling(d.receiveBilling);
        setReceiveContract(d.receiveContract);
        setReceiveDirect(d.receiveDirect);
        setReceiveMarketing(d.receiveMarketing);
        setReceiveSummaries(d.receiveSummaries);
        setEmail(d.emailAddress);
      }catch(err){
        setLoading(false);
        navigate("/login");
      }
    }
    fetch();
  }, [schoolIdInt, userIdInt, token, navigate]);

  const updatePreferences = async () => {
    setLoading(true);
    try{
      const data = {
        receiveAttendance,
        receiveBilling,
        receiveContract,
        receiveDirect,
        receiveMarketing,
        receiveSummaries
      };
      await UserAPI.saveUserPreferences(schoolIdInt, userIdInt, token, data);
      setLoading(false);
    }catch(err){

    }
  }


  return (
    <Screen ignoreAuth={true}>
      <Row>
        <Col sm={12} lg={12}>
          <Card title="Manage Message Preferences" loading={loading}>
           <p className="center">Managing the Message Preferences for: <strong>{email}</strong></p>

            <Row>
              <Col sm={12} lg={4}>
                <Form.Group className="form-group">
                  <Form.Label>
                    Receive Billing Messages
                  </Form.Label>
                  <Form.Select value={receiveBilling} onChange={e => setreceiveBilling(e.target.value)}>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                  <p className="small">These messages may include payment issues and invoices.</p>
                </Form.Group>
              </Col>
              <Col sm={12} lg={4}>
                <Form.Group className="form-group">
                  <Form.Label>
                    Receive Contract Messages
                  </Form.Label>
                  <Form.Select value={receiveContract} onChange={e => setReceiveContract(e.target.value)}>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                  <p className="small">These messages may include updates about your contract, including expiration reminders.</p>
                </Form.Group>
              </Col>
              <Col sm={12} lg={4}>
                <Form.Group className="form-group">
                  <Form.Label>
                    Receive Attendance Messages
                  </Form.Label>
                  <Form.Select value={receiveAttendance} onChange={e => setReceiveAttendance(e.target.value)}>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                  <p className="small">These messages may include attendance reminders and updates.</p>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={4}>
                <Form.Group className="form-group">
                  <Form.Label>
                    Receive Summary Messages
                  </Form.Label>
                  <Form.Select value={receiveSummaries} onChange={e => setReceiveSummaries(e.target.value)}>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                  <p className="small">These messages may include summaries of your activities and accomplishments.</p>
                </Form.Group>
              </Col>
              <Col sm={12} lg={4}>
                <Form.Group className="form-group">
                  <Form.Label>
                    Receive Marketing Messages
                  </Form.Label>
                  <Form.Select value={receiveMarketing} onChange={e => setReceiveMarketing(e.target.value)}>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                  <p className="small">These messages may include messages about deals and additional opportunities.</p>
                </Form.Group>
              </Col>
              <Col sm={12} lg={4}>
                <Form.Group className="form-group">
                  <Form.Label>
                    Receive Direct Messages
                  </Form.Label>
                  <Form.Select value={receiveDirect} onChange={e => setReceiveDirect(e.target.value)}>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                  <p className="small">These messages are custom sent directly from your school.</p>
                </Form.Group>
              </Col>
            </Row>

            <p>Please note: Even if you select "Yes", you may not receive these emails if your school has elected not to send them. Additionally, you may still receive transactional messages from the system, such as password reset and account information emails that are not within the control of your school. If you have any questions, please contact your school administrator.</p>
            

            <Row style={{ marginTop: 10 }}>
              <Col>
                <div className="d-grid gap-2">
                  <button className="btn btn-block btn-primary" onClick={updatePreferences}>Save</button>
                </div>
              </Col>
            </Row>

          </Card>
        </Col>
      </Row>
    </Screen>
  )
}