import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { DateTime } from "luxon";
import { NewsAPI } from "src/api";
import { Card } from "src/components/structure"
import { useGlobalState } from "src/state";

export const DashboardNews = (props: any) => {

  const [ loading, setLoading ] = useState(false);
  const [ news, setNews ] = useState<any[]>([]);

  const [ schoolId ] = useGlobalState("schoolId");

  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      try{
        const start = DateTime.now().minus({ months: 6 }).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
        const end = DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
        const result = await NewsAPI.getSchoolNews(schoolId, {start, end, count: 5 });
        setNews(result.body.data);
      }catch(err){ console.log(err); }
      setLoading(false);
    };
    fetchNews();
  }, [schoolId])

  return (
    <Card title="News" loading={loading}>
      {news.length === 0 ? (
        <p><strong>No recent news or announcements</strong></p>
      ) : (
        <Accordion>
          {news.map((n: any) => {
            const dt = DateTime.fromISO(n.posted).toLocal().toFormat("MM/dd/yy");
            return(
              <Accordion.Item key={n.id} eventKey={n.id}>
                <Accordion.Header>{dt} - {n.title}</Accordion.Header>
                <Accordion.Body>
                  {n.body}
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
        </Accordion>
      )}
    </Card>
  )
}