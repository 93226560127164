import { makeCall } from "./client";

export class KnowledgeAPI {

  /**
   * Get the material groups for the school
   * @param schoolId 
   * @param params 
   */
  public getMaterialGroups(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/materials`, params);
  }

  /**
   * Get a single material group
   * @param schoolId 
   * @param groupId 
   * @param params 
   */
  public getMaterialGroup(schoolId: number, groupId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/materials/groups/${groupId}`, params);
  }


  /**
   * Get the material
   * @param schoolId 
   * @param groupId 
   * @param materialId 
   * @param params 
   */
  public getMaterial(schoolId: number, groupId: number, materialId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/materials/groups/${groupId}/${materialId}`, params);
  }

  /**
   * Get materials for a student
   * @param schoolId 
   * @param userId 
   * @param params 
   */
  public getMaterialsForStudent(schoolId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}/materials`, params);
  }

  /**
   * Get all of the external attachments for a piece of material
   * @param schoolId 
   * @param groupId 
   * @param materialId 
   * @param params 
   */
  public getAttachmentsForMaterial(schoolId: number, groupId: number, materialId: number, params: any ={}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/materials/groups/${groupId}/${materialId}/attachments`, params);
  }

}