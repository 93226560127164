import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';
import { loadStripe, Appearance } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Nav from "./components/structure/Nav";
import AlertTemplate from "./components/structure/AlertTemplate";
import { useGlobalState } from "./state";
import { useEffect } from "react";
import { Provider, positions } from "@blaumaus/react-alert";

// paths
import { Dashboard } from "./components/screens/Dashboard/Dashboard";
import { Login } from "./components/screens/LoginAndReset/Login";
import { ResetPassword } from "./components/screens/LoginAndReset/ResetPassword";
import { Learning } from "./components/screens/Learning/Learning";
import { Profile } from "./components/screens/Account/ProfileScreen";
import { Schedule } from "./components/screens/Schedule/Schedule";

// NOT AUTHENTICATED!!!!
import { ManageMessagePreferences } from "./components/screens/ManageMessagePreferences/ManageMessagePreferences";
import { GoogleAd } from "./components/structure/Ad";

// stripe
const stripe = loadStripe(process.env.REACT_APP_STRIPE || "pk_MYg1zlteHFYG6Z4h68CLgWjAtiqvM");
const stripeAppearance: Appearance = {
  theme: "night",
  labels: "floating",
  variables: {
    colorPrimary: '#0570de',
    colorBackground: '#ffffff',
    colorText: '#30313d',
    colorDanger: '#df1b41',
    fontFamily: 'Ideal Sans, system-ui, sans-serif',
    spacingUnit: '2px',
    borderRadius: '4px',
    // See all possible variables below
  }
};

const alertOptions = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};

function App() {
  return (
  <Router>
    <div className="App container-fluid">
      <Row>
        <Col>
          <Nav />
        </Col>
      </Row>
      <Row style={{paddingTop: 15}}>
        <Elements stripe={stripe} options={{ appearance: stripeAppearance }}>
          <Provider template={AlertTemplate} {...alertOptions}>
            <Col className="col-md-10 offset-md-1">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/reset" element={<ResetPassword />} />
                <Route path="/reset/verify" element={<ResetPassword />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/learning" element={<Learning />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/schedule" element={<Schedule />} />

                {/* Same component, two paths, since the school may not be exposed but needs to for the unsub email */}
                <Route path="/messages/preferences" element={<ManageMessagePreferences />} />
                <Route path="/schools/:schoolId/users/:userId/messages/preferences" element={<ManageMessagePreferences />} />

              </Routes>
            </Col>
          </Provider>
        </Elements>
      </Row>
      <Row>
        <Col>
          <GoogleAd />
        </Col>
      </Row>
    </div>
  </Router>
  );
}

function Home() {
  const [ isLoggedIn ] = useGlobalState("isLoggedIn");
  const navigate = useNavigate();

  useEffect(() => {
    if(isLoggedIn){
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  })
  return (null );
}

export default App;
