import { Row, Col } from "react-bootstrap";
import { useGlobalState } from "../../../state";

import { Screen } from "../../structure";

import { ProfileCard } from "./ProfileCard";
import { FamilyCard } from "./FamilyCard";

export const Profile = (props: any) => {

  const [ userId, setUserId ] = useGlobalState("userId");
  const [ schoolId ] = useGlobalState("schoolId");

  const userSwitched = (newUser: any) => {
    setUserId(newUser.id);
  }

  return (
    <Screen>
      <Row>
        <Col lg={6} sm={12}>
          <ProfileCard userId={userId} schoolId={schoolId} />
        </Col>
        <Col lg={6} sm={12}>
          <FamilyCard userSwitched={userSwitched} />
        </Col>
      </Row>
    </Screen>
  )
}
