import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Markdown from "markdown-to-jsx";

import { KnowledgeAPI } from "../../../api";
import { LearningAttachmentViewer } from "./LearningAttachmentViewer";

interface ILearningDisplay {
  schoolId: number;
  userId: number;
  material: IMaterial;
  loading: boolean;
};

export interface IMaterial {
  content: string;
  groupId: number;
  groupName: string;
  groupOrder: number;
  groupType: string;
  materialId: number;
  materialOrder: number;
  status: 'assigned' | 'pending_verification' | 'completed' |'not_applicable';
  statusDate: string;
  title: string;
  verifiedCompleteBy: number;
}


export const LearningDisplay = (props: ILearningDisplay) => {
  const [ attachments, setAttachments ] = useState<any[]>([])

  // load the groups for the student
  useEffect(() => {
    const fetch = async () => {
      const materialResult = await KnowledgeAPI.getAttachmentsForMaterial(props.schoolId, props.material.groupId, props.material.materialId);
      setAttachments(materialResult.body.data);
    }
    if(props.material && props.material.materialId){
      fetch();
    }
  }, [props.material.materialId, props.material, props.schoolId])

  let statusDisplay = "Assigned";
  switch(props.material.status){
    case "assigned":
      statusDisplay = "Assigned";
      break;
    case "pending_verification":
      statusDisplay = "Pending Testing";
      break;
    case "completed":
      statusDisplay = "Completed";
      break;
    case "not_applicable":
      statusDisplay = "N/A";
      break;
  }

  return (
    <div>
      <Row className="row-spacing row-list">
        <Col style={{textAlign: "center"}}>
          <h4>{props.material.title}</h4>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "right"}}>
          <p className="small">Status: {statusDisplay}</p>
        </Col>
      </Row>
      <Row>
        <Col style={{color: "white"}}>
          <Markdown>{props.material.content}</Markdown>
        </Col>
      </Row>
      {attachments.map((att: any) => {
        return(
          <LearningAttachmentViewer schoolId={props.schoolId} userId={props.userId} material={props.material} attachment={att}  />
        )
      })}
    </div>
  )
}