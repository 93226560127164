import { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { SchoolsAPI, UserAPI } from "../../../api";
import { saveGlobalStateToLocalStorage, useGlobalState } from "../../../state";

import { Card, Screen } from "../../structure";

export const Login = (props: any) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [, setUserId ] = useGlobalState("userId");
  const [ , setSchoolId ] = useGlobalState("schoolId");
  const [ , setFamilyId ] = useGlobalState("familyId");
  const [ , setIsloggedIn ] = useGlobalState("isLoggedIn");
  const [ , setSchoolName ] = useGlobalState("schoolName");
  const [ , setUserDisplay ] = useGlobalState("userDisplay");

  const attemptLogin = async (username: string, password: string) => {
    setLoading(true);
    try{
      const userResult = await UserAPI.login(username, password);
      const user = userResult.body.data;
      const userId = user.id;
      const schoolResult = await SchoolsAPI.getSchoolsForUser(userId, { jwt: userResult.body.data.jwt});
      const schools = schoolResult.body.data;

      setUserId(user.id);
      setSchoolId(schools[0].id);
      setFamilyId(user.familyId);
      setIsloggedIn(true);
      setSchoolName(schools[0].name);
      setUserDisplay(`${user.firstName} ${user.lastName}`);
      saveGlobalStateToLocalStorage();
      navigate("/dashboard");
    }catch(err){
      setLoading(false);// TODO: show error
    }
  }

  // TODO: if logged in, redirect

  return (
    <Screen ignoreAuth={true}>
      <Row>
        <Col sm={12} lg={6} className="offset-lg-3">
          <Card title="Login" loading={loading}>
            <Row>
              <Col sm={12} lg={8}>
                <Form>
                  <Form.Group className="form-group">
                    <Form.Label>
                      Username
                    </Form.Label>
                    <Form.Control type="text" placeholder="Enter Username" value={username} onChange={e => setUsername(e.target.value)} autoComplete="username" />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>
                      Password
                    </Form.Label>
                    <Form.Control type="password" placeholder="Enter Password" value={password} onChange={e => setPassword(e.target.value)} autoComplete="current-password" />
                  </Form.Group>
                </Form>
              </Col>
              <Col sm={12} lg={4}>
                <p>This is the student-focused web application for managing your KDojang app. You must have an account with your school, which was likely set up for you when you joined their school. If you have any questions, please contact your school, <a href="mailto:support@treelightsoftware.com">Treelight Software</a>, or <Link to="/reset">try to reset your account</Link></p>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <div className="d-grid gap-2">
                  <button className="btn btn-block btn-primary" onClick={() => { attemptLogin(username, password); }}>Login</button>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Screen>
  )
}