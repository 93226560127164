import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ScheduleAPI, UserAPI } from "src/api";
import { Card } from "src/components/structure"
import { useGlobalState } from "src/state";

export const DashboardLastAttendance = (props: any) => {
  const [ loading, setLoading ] = useState(true);
  const [ family, setFamily ] = useState<any[]>([]);

  const [ userId ] = useGlobalState("userId");
  const [ schoolId ] = useGlobalState("schoolId");

  useEffect(() => {
    const fetch = async () => {
      // get the family members, then for each student or assistant,
      // get their attendance in the last month
      setLoading(true);
      const startDT = DateTime.now().minus({ months: 1 });
      const endDT = DateTime.now();
      const start = startDT.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'")
      const end = endDT.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
      try{
        const fResult = await UserAPI.getFamily(userId);
        const family: any[] = [];
        for(const f of fResult.body.data){
          if(f.schoolRole === "student" || f.schoolRole === "assistant") {
            const attResult = await ScheduleAPI.getUserAttendance(schoolId, f.id, { start, end });
            f.attendance = attResult.body.data;
            // we only care about the last attendance
            if(f.attendance.length > 0){
              const startDisplay = DateTime.fromSQL(f.attendance[0].startTime, { zone: "utc" }).toLocal().toFormat("MMM dd 'at' h:mm a")
              f.attendance[0].startDisplay = startDisplay;
            }
            family.push(f);
          }
        }
        setFamily(family);
      }catch(err){}
      setLoading(false);
    };
    fetch();
  }, [schoolId, userId]);

  return (
    <Card title="Attendance in Last Month" loading={loading}>
      <Row className="row-spacing row-list row-list-header">
            <Col xs={6} sm={6} lg={4}>
              Member
            </Col>
            <Col xs={6} sm={6} lg={1}>
              #
            </Col>
            <Col xs={6} sm={6} lg={4}>
              Last
            </Col>
            <Col xs={6} sm={6} lg={3}>
              Event
            </Col>
      </Row>
      {family.map((f: any) => {
        return (
          <Row key={f.id} className="row-spacing row-list">
            <Col xs={6} sm={6} lg={4}>
              {f.firstName} {f.lastName}
            </Col>
            <Col xs={6} sm={6} lg={1}>
              {f.attendance.length}
            </Col>
            <Col xs={6} sm={6} lg={4}>
              {f.attendance.length > 0 ? f.attendance[0].startDisplay : "--"}
            </Col>
            <Col xs={6} sm={6} lg={3}>
              {f.attendance.length > 0 ? f.attendance[0].name : "--"}
            </Col>
          </Row>
        );
      })}
    </Card>
  )
}