
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAlert } from "@blaumaus/react-alert";
import { UserAPI } from "src/api";
import { Card, Screen } from "../../structure";

export const ResetPassword = (props: any) => {

  const [ loading, setLoading ] = useState(true);
  const [ method, setMethod ] = useState("username");
  const [ username, setUsername ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ token, setToken ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ verifyPassword, setVerifyPassword ] = useState("");
  const [ step, setStep ] = useState("begin");
  const alert = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    // figure out the step
    const params = new URLSearchParams(window.location.search)
    const username = params.get("username");
    const token = params.get("token");
    if(username && username !== "" && token && token !== ""){
      setUsername(username);
      setToken(token);
      setStep("verify");
    } else {
      setStep("begin");
    }  
    setLoading(false);
  }, [])

  const beginReset = async () => {
    if(email === "" && username === ""){
      return alert.error("Enter a username or email");
    }
    setLoading(true);
    try{
      await UserAPI.resetPassword(username, email);
      alert.success("Check your email!");
    }catch(err){
      alert.error("Could not reset, contact your school.");
    }
    setLoading(false);
  }

  const resetPassword = async () => {
    if(username === "" || token === "" || password === "" || verifyPassword === ""){
      return alert.error("All fields are required");
    }
    if(password !== verifyPassword){
      return alert.error("Passwords do not match");
    }

    setLoading(true);
    try{
      await UserAPI.verifyPasswordToken(username, token, password);
      alert.success("Password changed, please login!");
      navigate("/login");
    }catch(err){
      alert.error("Could not reset, contact your school.");
    }
    setLoading(false);
  }

  if(step === "begin"){
    return (
      <Screen ignoreAuth={true}>
        <Row>
          <Col lg={6} sm={12} xs={12} className="offset-lg-3">
            <Card title="Reset Password" loading={loading}>
              <p>If you forgot your password, enter your username or email below. If you do not know your username, please contact your school. If you are a member of multiple KDojang schools, you will need to contact your school to receive the username created for that school.</p>
              <Row>
                <Form.Group style={{marginBottom: 20}}>
                  <Form.Label>Look Up By My</Form.Label>
                  <Form.Select value={method} onChange={(e) => { setMethod(e.target.value )}}>
                    <option value="username">Username</option>
                    <option value="email">Email</option>
                  </Form.Select>
                </Form.Group>
                {method === "username" ? (
                  <Form.Group>
                    <Form.Control placeholder="Enter Username" value={username} onChange={(e) => { setUsername(e.target.value )}} />
                  </Form.Group>
                ) : (
                  <Form.Group>
                    <Form.Control placeholder="Enter Email" value={email} onChange={(e) => { setEmail(e.target.value )}} />
                  </Form.Group>
                )}
                <Form.Group style={{marginTop: 20}}>
                  <div className="d-grid gap-2">
                    <Button className="btn btn-block btn-primary" onClick={beginReset}>Begin Reset</Button>
                  </div>
                </Form.Group>
              </Row>
            </Card>
          </Col>
        </Row>
      </Screen>
    );
  }
  
  if(step === "verify"){
    return (
      <Screen ignoreAuth={true}>
        <Row>
          <Col lg={6} sm={12} xs={12} className="offset-lg-3">
            <Card title="Verify Account" loading={loading}>
              <p>Verify your username and token and set a new password.</p>
              <Row>
                <Form.Group className="row-spacing">
                  <Form.Label>Username</Form.Label>
                  <Form.Control value={username} onChange={(e) => {setUsername(e.target.value)}} />
                </Form.Group>
                <Form.Group className="row-spacing">
                  <Form.Label>Token</Form.Label>
                  <Form.Control value={token} onChange={(e) => {setToken(e.target.value)}} />
                </Form.Group>
                <Form.Group className="row-spacing">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control type="password" value={password} onChange={(e) => {setPassword(e.target.value)}} />
                </Form.Group>
                <Form.Group className="row-spacing">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control type="password" value={verifyPassword} onChange={(e) => {setVerifyPassword(e.target.value)}} />
                </Form.Group>
                <Form.Group style={{marginTop: 20}}>
                  <div className="d-grid gap-2">
                    <Button className="btn btn-block btn-primary" onClick={resetPassword}>Reset Password</Button>
                  </div>
                </Form.Group>
              </Row>
            </Card>
          </Col>
        </Row>
      </Screen>
    );
  }

  return (<span></span>)
}