import { useEffect } from "react"

export const GoogleAd = (props: any) => {

  useEffect(() => {
    if(window){
      setTimeout(() => {
        ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
      })
    }
  }, []);

  return (
    <div>
      <ins className="adsbygoogle"
          style={{display: "block"}}
          data-ad-client="ca-pub-5555519429647674"
          data-ad-slot="8826089382"
          data-ad-format="auto"
          data-adtest="on"
          data-full-width-responsive="true" />
    </div>
  )
}