import { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { KnowledgeAPI } from "../../../api";
import { useGlobalState } from "../../../state";

import { Card, Screen } from "../../structure";
import { LearningDisplay } from "./LearningDisplay";

export const Learning = (props: any) => {
  const [loading ] = useState(false);
  const [materials, setMaterials] = useState<any>({});
  const [types, setTypes] = useState<any[]>([]);;
  const [materialGroups, setMaterialGroups] = useState<any>({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedSection, setSelectedSection] = useState(0);

  const [userId ] = useGlobalState("userId");
  const [schoolId ] = useGlobalState("schoolId");

  // load the groups for the student
  useEffect(() => {
    const fetch = async () => {
      const materialResult = await KnowledgeAPI.getMaterialsForStudent(schoolId, userId);

      // we want this to be in the shape of 
      // { groupType: { groupName: []}}
      const materialsMap: any = {};
      // groups should be { type: [name: order:]}
      const materialGroupsMap: any = {};

      const typesMap: any = {};


      for (const m of materialResult.body.data) {
        const t = m.groupType;
        const n = m.groupName;
        if (!materialsMap[t]) {
          materialsMap[t] = {};
          materialGroupsMap[t] = [];
          typesMap[t] = t;
        }
        if (!materialsMap[t][n]) {
          materialsMap[t][n] = [];
          materialGroupsMap[t].push({ name: n, order: m.groupOrder });
        }
        materialsMap[t][n].push(m);
      }

      // let's loop and sort
      for (const type of Object.keys(materialsMap)) {
        for (const group of Object.keys(materialsMap[type])) {
          materialsMap[type][group].sort((a: any, b: any) => {
            return a.materialOrder > b.materialOrder ? 1 : -1;
          })
        }
      }
      for (const type of Object.keys(materialGroupsMap)) {
        materialGroupsMap[type].sort((a: any, b: any) => {
          return a.order > b.order ? 1 : -1;
        })
      }
      const types = Object.keys(typesMap);
      setTypes(types);

      setMaterialGroups(materialGroupsMap)
      setMaterials(materialsMap);

    }

    fetch();
  }, [userId, schoolId])

  const handleSelectedType = (e: any) => {
    setSelectedSection(0);
    setSelectedGroup("");
    setSelectedType(e.target.value);
  }
  const handleSelectedGroup = (e: any) => {
    setSelectedSection(0);
    setSelectedGroup(e.target.value);
  }
  const handleSelectedSection = (e: any) => {
    const index = parseInt(e.target.value);
    setSelectedSection(index);
  }

  return (
    <Screen>
      <Row>
        <Col sm={12} lg={6} className="offset-lg-3">
          <Card title="Learning" loading={loading}>
            <Form>
              <Form.Group>
                <Form.Label>Knowledge Type</Form.Label>
                <Form.Select value={selectedType} onChange={handleSelectedType}>
                  <option disabled={true} value="">Select a Type</option>
                  {types.map((t) => {
                    return <option key={t} value={t}>{t[0].toUpperCase() + t.substring(1, t.length)}</option>
                  })}
                </Form.Select>
              </Form.Group>
              {selectedType !== "" && (
                <Form.Group>
                  <Form.Label>Knowledge Group</Form.Label>
                  <Form.Select value={selectedGroup} onChange={handleSelectedGroup}>
                    <option disabled={true} value="">Select a Grouping</option>
                    {materialGroups[selectedType].map((t: any) => {
                      return <option key={t.name} value={t.name}>{t.name}</option>
                    })}
                  </Form.Select>
                </Form.Group>
              )}
              {selectedType !== "" && selectedGroup !== "" && (
                <Form.Group>
                  <Form.Label>Section</Form.Label>
                  <Form.Select value={selectedSection} onChange={handleSelectedSection}>
                    <option disabled={true} value="">Select a Section</option>
                    {materials[selectedType][selectedGroup].map((t: any, index: number) => {
                      return <option key={t.title} value={index}>{t.title}</option>
                    })}
                  </Form.Select>
                </Form.Group>
              )}
            </Form>
            <hr />
            <Row>
              <Col>
                {selectedType !== "" && selectedGroup !== "" && (
                  <LearningDisplay schoolId={schoolId} userId={userId} loading={loading} material={materials[selectedType][selectedGroup][selectedSection]}/>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Screen>
  );
}