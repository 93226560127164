import { Row, Col } from "react-bootstrap";
import { IMaterial } from "./LearningDisplay";

interface ILearningAttachmentProps {
  schoolId: number;
  userId: number;
  material: IMaterial;
  attachment: IMaterialAttachment;
}

interface IMaterialAttachment {
  attachmentType: "external" | "file" | "youtube";
  description: string;
  id: number;
  location: string;
  materialId: number;
  schoolId: number;
  title: string;
}

export const LearningAttachmentViewer = (props: ILearningAttachmentProps) => {

  // right now we only support youtube
  if(props.attachment.attachmentType !== "youtube"){
    return null;
  }

  return (
    <Row>
      <Col>
        <iframe 
          style={{width: "100%"}} 
          width="560" 
          height="315" 
          src={props.attachment.location} 
          frameBorder="0" 
          title={props.attachment.title ? props.attachment.title : ""} 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen></iframe>
      </Col>
    </Row>
  )
}