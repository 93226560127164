import * as events from "./events";
import * as kn from "./knowledge";
import * as news from "./news";
import * as schedule from "./schedule";
import * as schools from "./schools";
import * as user from "./user";

const EventsAPI = new events.EventsAPI();
const KnowledgeAPI = new kn.KnowledgeAPI();
const NewsAPI = new news.NewsAPI();
const ScheduleAPI = new schedule.ScheduleAPI();
const SchoolsAPI = new schools.SchoolsAPI();
const UserAPI = new user.UserAPI();

export { EventsAPI, KnowledgeAPI, NewsAPI, ScheduleAPI, SchoolsAPI, UserAPI };