import { useEffect } from "react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { capitalizeFirst } from "src/utils";
import { UserAPI } from "../../../api";
import { useGlobalState } from "../../../state";

import { Card } from "../../structure";

export const FamilyCard = (props: any) => {
  const [loading ] = useState(false);
  const [familyMembers, setFamilyMembers] = useState([]);

  const [userId, setUserId] = useGlobalState("userId");
  const [userDisplay, setUserDisplay] = useGlobalState("userDisplay");

  useEffect(() => {
    const fetch = async () => {
      const familiesResult = await UserAPI.getFamily(userId);
      setFamilyMembers(familiesResult.body.data);
    }
    fetch();
  }, [userId])

  const switchUser = async (user: any) => {
    setUserId(user.id);
    setUserDisplay(`${user.firstName} ${user.lastName}`);
    user.userDisplay = userDisplay;
    props.userSwitched(user);
  };


  return (
    <Row>
      <Col>
        <Card title="Family Members" loading={loading}>
          <Row style={{marginBottom: 10}} className="align-middle row-list row-list-header">
            <Col>Member</Col>
            <Col>Status</Col>
            <Col></Col>
          </Row>
          {familyMembers.map((member: any) => {
            return(
              <Row style={{marginBottom: 10}} key={member.id} className="align-middle row-list">
                <Col>{member.firstName} {member.lastName}</Col>
                <Col>{capitalizeFirst(member.schoolRole)}</Col>
                <Col>{member.schoolRole !== "inactive" && member.schoolRole !== "user" && member.id !== userId && 
                  (<button className="btn btn-block btn-link" style={{padding:0}} onClick={() => {switchUser(member)}}>Switch</button>)}
                  {member.id === userId && (<strong>Current</strong>)}
                </Col>
              </Row>
            )
          })}
        </Card>
      </Col>
    </Row>
  )
}